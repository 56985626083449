import React from 'react'
import { PageProps, Link } from 'gatsby'
import classNames from 'classnames'

import BookADemo from '../Button/BookADemo'
import Seo from '../Seo/Seo'

import scrollToElement from '../../utils/scrollToElement'

import Logo from '../../assets/img/logo.svg'
import LogoDark from '../../assets/img/logo-dark.svg'

import '../../assets/scss/app.scss'

export default function App(props: PageProps): JSX.Element {
  return (
    <>
      <Seo />
      <header
        className={classNames('bg-green relative z-20', {
          'py-4 md:py-14': props.path !== '/',
          'pt-4 md:pt-14': props.path === '/'
        })}
      >
        <div className='max-w-6xl mx-auto px-4 flex justify-between items-center'>
          <Link to='/'>
            <LogoDark className='w-[6.25rem]' />
          </Link>
          <BookADemo />
        </div>
      </header>
      <main>
        {props.children}
      </main>
      <footer className='py-14'>
        <div className='max-w-6xl mx-auto px-4 flex justify-between items-start md:items-center'>
          <Logo className='w-[6.25rem]' />
          <ul className='text-right space-y-5 md:space-y-0 md:space-x-12 md:flex md:items-center'>
            <li>
              <button
                className='hover:underline'
                onClick={(event) => scrollToElement(event, '#book-a-demo')}
              >
                Book A Demo
              </button>
            </li>
            <li>
              <a
                href='https://breaksagency.com/'
                className='hover:underline'
              >
                Breaks
              </a>
            </li>
            <li>
              © Breaks Agency Ltd {new Date().getFullYear()}
            </li>
          </ul>
        </div>
      </footer>
    </>
  )
}
