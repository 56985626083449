import React, { HTMLAttributes, ReactElement } from 'react'
import classNames from 'classnames'

import Link from '../Link/Link'

interface Props extends HTMLAttributes<HTMLElement> {
  to: string,
  className?: string,
  type?: 'button' | 'submit' | 'reset' | undefined,
  onClick?: React.MouseEventHandler<HTMLElement>
}

export const buttonClassNames: string = 'font-inter bg-black text-white text-sm py-4 px-6 sm:px-8 hover:bg-white hover:text-black hover:shadow-xl focus:bg-white focus:text-black focus:shadow-xl focus:outline-none transition-all duration-300 ease-smooth'

export default function Button(props: Props): ReactElement {
  return (
    <Link
      to={props.to}
      className={classNames(buttonClassNames, props.className)}
      onClick={props.onClick && props.onClick}
    >
      {props.children}
    </Link>
  )
}
