import { graphql, useStaticQuery } from 'gatsby'

interface SiteMetadataObject {
  siteMetadata: {
    siteName: string,
    siteUrl: string,
    description: string
  }
}

const useSiteMetadata = (): SiteMetadataObject => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteName
            siteUrl
            description
          }
        }
      }
    `
  )

  return site
}

export default useSiteMetadata
