/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 * See: https://www.gatsbyjs.org/docs/browser-apis/#wrapPageElement
 */

import React from 'react'

import App from './src/components/App/App'

import 'lazysizes'

const wrapPageElement = ({ element, props }) => (
  <App {...props}>
    {element}
  </App>
)

// 'wrapPageElement' must be a named export
export { wrapPageElement }
