import React from 'react'
import { navigate } from 'gatsby'
import jump from 'jump.js'

export default function scrollToElement (event: React.MouseEvent<HTMLElement>, target: string): void {
  event.preventDefault()

  if (location.pathname !== '/') {
    navigate(`/${target}`)
  } else {
    jump(target)
  }
}
