import React from 'react'

import Button from '../Button/Button'
import scrollToElement from '../../utils/scrollToElement'

export default function BookADemo(): JSX.Element {

  return (
    <Button
      to='#book-a-demo'
      onClick={(event) => scrollToElement(event, '#book-a-demo')}
    >
      Book A Demo
    </Button>
  )
}
