import React from 'react'
import { Helmet } from 'react-helmet'

import useSiteMetadata from '../../hooks/useSiteMetadataQuery'

import favicon from '../../../static/assets/icon.png'

interface SeoProps {
  title?: string,
  siteName?: string,
  description?: string,
  image?: string,
  siteUrl?: string,
  slug?: string,
  robots?: string
}

const Seo = ({ ...data }: SeoProps) => {
  const { siteMetadata } = useSiteMetadata()

  /* Merge siteMetadata with props */
  const {
    title,
    siteName,
    description,
    image,
    siteUrl,
    slug,
    robots
  } = Object.assign({}, siteMetadata, data)

  // Generate a full URL to the content & image path
  const url = slug ? (siteUrl + slug) : siteUrl
  //const imageUrl = image || `${siteUrl}/assets/icon.png`
  const imageUrl = 'https://ucarecdn.com/a7b6d760-fd50-4c72-a6fa-346b761763e3/1200.jpg'

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
        class: 'antialiased'
      }}
      titleTemplate={`%s | ${siteName}`}
      defaultTitle={siteName}
    >
      <title>{title}</title>

      {/* Resource Hints */}
      {['https://www.google-analytics.com'].map(resource => <link key={resource} rel='preconnect dns-prefetch' href={resource} />)}

      <meta name='description' content={description} />

      {/* Open Graph Tags */}
      <meta property='og:type' content='website' />
      <meta property='og:title' content={title ? `${title} - ${siteName}` : siteName} />
      <meta property='og:description' content={description} />
      <meta property='og:url' content={url} />
      <meta property='og:image' content={imageUrl} />
      <meta property='og:image:alt' content={description} />
      <meta name='twitter:card' content='summary_large_image' />

      {robots && <meta name='robots' content={robots} />}

      {/* Google Search Console */}
      {/* <meta name='google-site-verification' content='HHLwzSZzpjD1VAaf37WQyTMawoSfVFFtqPXVT3pThJc' /> */}

      {/* GIF Favicon */}
      <link rel='shortcut icon' type='image/gif' href={favicon} />

    </Helmet>
  )
}

export default Seo
